.hero {
  width: 100vw;
  height: 125vw;
  color: $white;
  position: relative;
  @include media-breakpoint-up(sm) {
    height: 100vw;
  }
  @include media-breakpoint-up(lg) {
    height: calc(100vh - $headerHeightSM);
  }
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    .heromovie {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $body-color, $alpha: 0.5);
    }
  }
  .cont {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 30px;
    @include media-breakpoint-up(lg) {
      gap: 40px;
    }
    .catchCopy {
      margin: 0;
      line-height: 1;
      text-align: center;
      width: 100%;
      padding: 0 30px;
      img {
        width: 100%;
        max-width: 445px;
        height: auto;
      }
    }
    .leadCopy {
      margin: 0;
      line-height: 2;
      font-family: $font-family-serif;
      font-size: 13px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
        text-align: center;
      }
      @include media-breakpoint-up(lg) {
        letter-spacing: 0.2em;
      }
    }
  }
  .scrollIcon {
    position: absolute;
    width: 100%;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      font-size: 12px;
      letter-spacing: 0.1em;
      font-family: $font-family-en;
      &::after {
        content: "";
        width: 1px;
        height: 30px;
        background-color: $white;
        animation: scrollLine 1s linear -2s infinite;
        transform-origin: top;
      }
    }
  }
}
@keyframes scrollLine {
  0% {
    transform: scaleY(0);
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
    opacity: 0;
  }
}
