.itemList {
  display: block;
  text-decoration: none;
  color: $body-color;
  transition: $transition-base;
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: calc(340 / 340 * 100%); //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }
  }
  .cont {
    margin-top: 10px;
    @include media-breakpoint-up(sm) {
      margin-top: 15px;
    }
    .name {
      font-size: 13px;
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
  &:hover {
    color: $primary;
    .img {
      img {
        transform: scale(1.05);
      }
    }
  }
}
