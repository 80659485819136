.feature {
  @include media-breakpoint-up(sm) {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
  }
  .item {
    border-bottom: 1px solid #707070;
    padding: 20px 0;
    flex: 1;
    &:last-child {
      border-bottom: none;
    }
    @include media-breakpoint-up(sm) {
      border-right: 1px solid #707070;
      border-bottom: 0;
      padding: 0;
      padding-right: 20px;
      padding-left: 20px;
      &:last-child {
        border-right: none;
      }
    }
    .header {
      .title {
        text-align: center;
        .title-ja {
          font-size: 14px;
          @include media-breakpoint-up(lg) {
            font-size: 20px;
          }
        }
        .title-en {
          font-size: 24px;
          font-family: $font-family-en;
          @include media-breakpoint-up(lg) {
            font-size: 36px;
          }
        }
      }
      .img {
        text-align: center;
        img {
        }
      }
    }
  }
}
