.newsList {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    border-bottom: 1px solid $border-color;
    a {
      display: flex;
      gap: 15px;
      align-items: center;
      text-decoration: none;
      color: $body-color;
      padding: 20px 0;
      flex-wrap: wrap;
      transition: $transition-base;
      @include media-breakpoint-up(sm) {
        align-items: flex-start;
        padding: 25px 0;
      }
      .data {
        display: flex;
        align-items: center;
        .date {
          color: #888888;
          font-size: 12px;
          padding-right: 10px;
          font-family: $font-family-serif;
        }
        .new {
          font-size: 11px;
          color: #bf4f3c;
          padding-right: 10px;
        }
        .cat {
          font-size: 11px;
          padding: 0px 10px;
          line-height: 1;
          white-space: nowrap;
          border-left: 1px solid #cdcdcd;
          color: #888888;
        }
      }

      .title {
        font-size: 15px;
        font-weight: bold;
        flex-basis: 100%;
      }
      &:hover {
        background-color: $gray-100;
      }
    }
  }
}
