#footer {
  background-color: $gray-100;
  padding: 45px 6vw;
  @include media-breakpoint-up(sm) {
    padding: 90px 6vw;
  }
  > .inner {
    display: grid;
    grid-template:
      "logo"
      "..." 15px
      "info"
      "..." 15px
      "sns"
      "..." 30px
      "gnav"
      "..." 30px
      "unav"
      "..." 15px
      "copyright"
      / 1fr;
    @include media-breakpoint-up(sm) {
      grid-template:
        "gnav gnav gnav"
        "... .... ..." 45px
        "... logo ..."
        "... .... ..." 30px
        "... sns  ..."
        "... .... ..." 30px
        "... info ..."
        "... .... ..." 60px
        "unav .... copyright"
        / 1fr auto 1fr;
    }
    @include media-breakpoint-up(lg) {
      grid-template:
        "logo ... gnav"
        ".... ... ...." 30px
        "sns  ... info"
        ".... ... ...." 60px
        "unav ... copyright"
        / auto 1fr auto;
    }
    .logo {
      grid-area: logo;
      display: flex;
      justify-content: center;
      img {
        height: 50px;
        width: auto;
      }
    }
    .sns {
      grid-area: sns;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      font-size: 24px;
      @include media-breakpoint-up(lg) {
        align-items: flex-start;
      }
      li {
        a {
          color: $body-color;
          text-decoration: none;
        }
      }
    }
    .gnav {
      grid-area: gnav;
      font-size: 13px;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        height: 100%;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        border-top: 1px solid $border-color;
        @include media-breakpoint-up(sm) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 15px;
          border-top: none;
          font-weight: bold;
        }
        @include media-breakpoint-up(lg) {
          justify-content: flex-end;
          gap: 30px;
        }
        li {
          border-bottom: 1px solid $border-color;
          width: 100%;
          @include media-breakpoint-up(sm) {
            border-bottom: none;
            width: auto;
          }
          a {
            color: $body-color;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            @include media-breakpoint-up(sm) {
              padding: 0;
            }
            &::after {
              font-family: "Font Awesome 5 Pro";
              font-weight: 400;
              content: "\f054";
              @include media-breakpoint-up(sm) {
                display: none;
              }
            }
          }
        }
      }
    }
    .info {
      grid-area: info;
      display: flex;
      justify-content: center;
      font-size: 12px;
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        text-align: right;
      }
      .inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        p {
          margin: 0;
        }
      }
    }
    .unav {
      grid-area: unav;
      font-size: 10px;
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align: left;
      }
      a {
        color: $secondary;
      }
    }
    .copyright {
      grid-area: copyright;
      font-size: 10px;
      color: $secondary;
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align: right;
      }
    }
  }
}
