.catchCopy {
  font-size: 24px;
  line-height: 1.78;
  text-align: left;
  font-family: $font-family-serif;
  @include media-breakpoint-up(sm) {
    font-size: 26px;
    text-align: center;
  }
  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
}
