.ctaArea {
  background-color: $primary;
  color: $white;
  padding: 30px 6vw 60px;
  @include media-breakpoint-up(sm) {
    padding: 36px 6vw 90px;
  }
  .header {
    position: relative;
    margin-bottom: 15px;
    .title-en {
      position: relative;
      z-index: 1;
      font-family: $font-family-en;
      color: $body-color;
      opacity: 0.1;
      mix-blend-mode: multiply;
      line-height: 1;
      text-align: center;
      @include fontSize(75);
      @include media-breakpoint-up(sm) {
        @include fontSizeSM(120);
      }
      @include media-breakpoint-up(lg) {
        @include fontSizeLG(165);
      }
    }
    .title-ja {
      font-size: 27px;
      letter-spacing: 0.1em;
      font-weight: bold;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding-top: 5%;
      @include media-breakpoint-up(lg) {
        padding-top: 3%;
        @include fontSizeLG(32);
      }
    }
  }
  .body {
    text-align: center;
    > .text {
      font-size: 13px;
      margin-bottom: 30px;
      @include media-breakpoint-up(sm) {
        font-size: 14px;
        margin-bottom: 45px;
      }
    }
  }
}
