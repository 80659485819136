.moreBtn {
  display: inline-flex;
  width: 300px;
  height: 64px;
  border: 1px solid $border-color;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: $white;
  position: relative;
  color: $body-color;
  text-decoration: none;
  transition: $transition-base;
  @include media-breakpoint-up(sm) {
    width: 400px;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 100%;
  }
  .text {
    font-family: $font-family-en;
    flex: 1;
    text-align: center;
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;
    right: 0;
    top: 0;
    font-size: 75%;
  }
  &::before {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 100%;
    height: 100%;
    content: "";
    border-right: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    transition: $transition-base;
  }
  &.inline {
    width: auto;
    min-width: 200px;
    max-width: 100%;
    height: 42px;
    &::before {
      bottom: -5px;
      right: -5px;
    }
  }
  &.cta {
    border: 1px solid $white;
    color: $gold;
    &::before {
      border-right: 1px solid $white;
      border-bottom: 1px solid $white;
    }
  }
  &.primary {
    border: 1px solid $primary;
    color: $white;
    background-color: $primary;
    &::before {
      border-right: 1px solid $primary;
      border-bottom: 1px solid $primary;
    }
  }
  &:hover {
    transform: translate(5px, 5px);
    &::before {
      bottom: -5px;
      right: -5px;
    }
  }
}
