@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
}
img {
  max-width: 100%;
  height: auto;
}
main {
  overflow-x: hidden;
}
[v-cloak] {
  display: none;
}
@mixin fontSize($font_size: 10) {
  font-size: calc($font_size / 390 * 100vw);
}
@mixin fontSizeSM($font_size: 10) {
  font-size: calc($font_size / 820 * 100vw);
}
@mixin fontSizeLG($font_size: 10) {
  font-size: calc($font_size / 1366 * 100vw);
}
@import "components/header";
@import "components/toggleMenu";
@import "components/layout";
@import "components/hero";
@import "components/leadArea";
@import "components/summary";
// @import "components/outline";
// @import "components/digest";
// @import "components/digestCover";
@import "components/article";
@import "components/pagination";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/tab";
// @import "components/coverBgImg";
// @import "components/sideMenu";
// @import "components/tagCloud";
// @import "components/narrowThumbnailLink";
// @import "components/imgList";
@import "components/carousels";
@import "components/formTable";
// @import "components/brandTable";
// @import "components/flow";
// @import "components/faq";
@import "components/lineupArea";
@import "components/lineup";
@import "components/newsList";
@import "components/menuItem";
@import "components/profileArea";
@import "components/eventHeader";
@import "components/itemList";
@import "components/moreText";
@import "components/shopData";
@import "components/galleryBox";
// @import "components/newsBar";
// @import "components/newsArchive";
// @import "components/catchCopyBar";
// @import "components/catchCopyBorder";
// @import "components/anchorLinks";
// @import "components/outlineCard";
@import "components/midashi1";
@import "components/midashi2";
@import "components/midashi3";
@import "components/catchCopy";
@import "components/feature";
@import "components/moreBtn";
@import "components/btn-cta";
@import "components/pankuzu";
@import "components/ctaArea";
@import "components/footer";
// @import "components/vif";
