.nav-tabs-wrap {
  // width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
  overflow-x: auto;
  @include media-breakpoint-down(sm) {
    margin-bottom: -2px;
    margin-right: -6vw;
    margin-left: -6vw;
    width: calc(100% + 12vw);
  }
  scrollbar-width: none; /*Firefox対応のスクロールバー非表示コード*/
  -ms-overflow-style: none; /*Internet Explore対応のスクロールバー非表示コード*/
  &::-webkit-scrollbar {
    display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
  }
}
.nav-tabs {
  border-bottom: none;
  @include media-breakpoint-down(sm) {
    width: max-content;
    // padding: 0 5px;
  }
  .nav-item {
    font-weight: bold;
    .nav-link {
      padding: 3px;
      height: calc(100% + 1px);
      .inner {
        background-color: $gray-100;
        padding: 10px 15px;
        height: 100%;
        line-height: 1.5;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-up(sm) {
          font-size: 13px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }
      }
      &.active {
        .inner {
          background-color: transparent;
        }
      }
      &:hover {
        .inner {
          background-color: transparent;
        }
      }
    }
  }
}
.tab-content {
  &.box {
    border: 1px solid $body-color;
    margin-top: -1px;
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      margin-right: -6vw;
      margin-left: -6vw;
      width: calc(100% + 12vw);
    }
  }
}
