.secondHeader {
  position: relative;
  .bg {
    padding-left: 12vw;
    margin-bottom: -25px;
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(sm) {
      margin-bottom: -45px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding-left: 36vw;
    }
    .img {
      position: relative;
      width: 100%;
      overflow: hidden;
      &::before {
        content: "";
        width: 100%;
        padding-top: calc(380 / 900 * 100%); //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .cont {
    padding: 0 6vw;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    @include media-breakpoint-up(sm) {
      gap: 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 12vw;
      gap: 45px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
    }
    > .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      .title-en {
        @include fontSize(30);
        letter-spacing: 0.05em;
        text-transform: capitalize;
        line-height: 1.2;
        font-family: $font-family-en;
        font-weight: normal;
        display: inline-block;
        padding: 10px 15px;
        padding-left: 0;
        background-color: $white;
        margin: 0;
        @include media-breakpoint-up(sm) {
          @include fontSizeSM(45);
        }
        @include media-breakpoint-up(lg) {
          @include fontSizeLG(66);
        }
      }
      .title-ja {
        @include fontSize(14);
        letter-spacing: 0.1em;
        line-height: 1.2;
        font-family: $font-family-serif;
        font-weight: bold;
        display: inline-block;
        padding: 10px;
        padding-left: 0;
        background-color: $white;
        margin: 0;
        @include media-breakpoint-up(sm) {
          @include fontSizeSM(14);
        }
        @include media-breakpoint-up(lg) {
          @include fontSizeLG(15);
        }
      }
    }
    > .footer {
      .lnav {
        font-size: 12px;
        font-family: $font-family-serif;
        @include media-breakpoint-up(sm) {
          font-size: 14px;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          @include media-breakpoint-up(sm) {
            flex-direction: row;
            gap: 24px;
          }
          @include media-breakpoint-up(lg) {
            flex-direction: column;
          }
          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            &::before {
              font-family: "Font Awesome 5 Pro";
              content: "\f107";
            }
            a {
              color: $body-color;
              transition: $transition-base;
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
}
