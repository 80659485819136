.widelayout {
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  &.el-brand {
    position: relative;
    .contentsContainer,
    .brandContainer,
    .narrowContainer {
      position: relative;
      z-index: 2;
    }
    &::before {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      content: "";
      width: 60vw;
      height: 36vw;
      background-color: $gold-100;
      opacity: 0.5;
    }
  }
}
.brandContainer {
  padding: 0 6vw;
}
.narrowContainer {
  max-width: calc(1100px + 12vw);
  margin: 0 auto;
  padding: 0 6vw;
}
.brandSpace {
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 120px;
  }
}
.contentsContainer {
  max-width: calc(800px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
}
.magazineCont {
  max-width: 1200px;
  margin: 0 auto;
  .sideCont {
    margin-top: 60px;
    @include media-breakpoint-up(sm) {
      margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .mainCont {
      width: 800px;
    }
    .sideCont {
      width: 320px;
    }
  }
}
.bg-gray {
  background-color: $gray-100;
}
