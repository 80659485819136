$headerHeight: 70px;
$headerHeightSM: 90px;
#header {
  width: 100%;
  height: $headerHeight;
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  background-color: $white;
  padding: 10px 15px;
  @include media-breakpoint-up(sm) {
    height: $headerHeightSM;
  }
  @include media-breakpoint-up(xl) {
    padding: 0;
    display: grid;
    grid-template:
      "... logo ... gnav unav cta"
      / calc(30 / 1400 * 100vw) auto 1fr auto auto calc(150 / 1400 * 100vw);
  }
  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    grid-area: logo;
    img {
      height: 36px;
      width: auto;
    }
    @include media-breakpoint-up(xl) {
      img {
        width: min(calc(218 / 1400 * 100vw), 218px);
        height: auto;
      }
    }
  }
  .gnav {
    display: none;
    grid-area: gnav;
    @include media-breakpoint-up(xl) {
      display: block;
      padding: 0 25px;
      ul {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 7px;
            text-decoration: none;
            height: $headerHeightSM;
            padding: 0 calc(25 / 1400 * 100vw);
            transition: $transition-base;
            &:hover {
              background-color: $gold-100;
            }
            .en {
              font-family: $font-family-en;
              font-size: min(calc(15 / 1400 * 100vw), 15px);
              line-height: 1;
              color: $black;
              text-transform: capitalize;
            }
            .ja {
              font-size: min(calc(11 / 1400 * 100vw), 11px);
              line-height: 1;
              color: $secondary;
            }
          }
        }
      }
    }
  }
  .unav {
    display: none;
    grid-area: unav;
    @include media-breakpoint-up(xl) {
      display: block;
      height: 100%;
      padding: 0 calc(25 / 1400 * 100vw);
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 15px;
        left: 0;
        width: 1px;
        height: calc(100% - 30px);
        background-color: $border-color;
      }
      ul {
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 7px;
        justify-content: center;
        align-items: flex-start;
        font-size: min(calc(12 / 1400 * 100vw), 12px);
        li {
          a {
            color: $body-color;
            text-decoration: none;
            transition: $transition-base;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
  .cta {
    display: none;
    grid-area: cta;
    @include media-breakpoint-up(xl) {
      display: block;
      width: 100%;
      height: 100%;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: $primary;
        color: $white;
        text-decoration: none;
        transition: $transition-base;
        font-family: $font-family-en;
        font-size: min(calc(15 / 1400 * 100vw), 15px);
        text-transform: capitalize;
        &:hover {
          background-color: shade-color($primary, 10%);
        }
      }
    }
  }
}
