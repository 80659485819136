.eventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  .img {
    flex-basis: 100%;
    @include media-breakpoint-up(sm) {
      flex-basis: 50%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 30%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .header {
    flex-basis: 100%;
    @include media-breakpoint-up(sm) {
      flex: 1;
    }
    .title {
      font-size: 30px;
      line-height: 1.2;
      font-family: $font-family-sans-serif;
      font-weight: bold;
      margin: 0;
      @include media-breakpoint-up(lg) {
        font-size: 36px;
        letter-spacing: 0.1em;
      }
    }
  }
  .cont {
    flex-basis: 100%;
    .leadCopy {
      font-size: 14px;
      line-height: 1.75;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
