.lineup {
  text-decoration: none;
  display: block;
  .img {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before {
      content: "";
      width: 100%;
      padding-top: calc(453 / 340 * 100%); //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .hover {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: rgba($body-color, 0.5);
      opacity: 0;
      transition: $transition-base;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      .cont {
        text-align: center;
        border-radius: 0px;
        border: 1px solid $white;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 128px;
        height: 128px;
        color: $white;
        font-family: $font-family-en;
        letter-spacing: 0.2em;
      }
    }
  }
  > .cont {
    color: $body-color;
    .name {
      font-size: 16px;
      font-weight: bold;
      margin-top: 15px;
    }
    .detail {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  &:hover {
    .img {
      .hover {
        opacity: 1;
      }
    }
    .cont {
      color: $primary;
    }
  }
}
