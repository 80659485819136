.moreText {
  text-decoration: none;
  color: $body-color;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    height: 1px;
    flex-grow: 1;
    background-color: #cdcdcd;
    margin-right: 1rem;
  }
  &::after {
    content: "";
    height: 1px;
    flex-grow: 1;
    background-color: #cdcdcd;
    margin-left: 1rem;
  }
  .arrow {
    i {
      font-size: 0.75em;
    }
  }
  .text {
    font-size: 14px;
    font-weight: bold;
    padding-top: 1.5px;
  }
}
