.leadArea {
  .header {
    img {
      width: 100%;
      height: auto;
    }
  }
  .body {
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    @include media-breakpoint-up(lg) {
      align-items: stretch;
    }
    .cont {
      padding: 0 6vw 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
      @include media-breakpoint-up(sm) {
        gap: 40px;
        flex: 1;
        padding: 0 3vw 60px 6vw;
      }
      @include media-breakpoint-up(lg) {
        padding: 60px 6vw;
        padding-bottom: calc((110 / 1400 * 100vw) + 60px);
        justify-content: center;
      }
      > .catchCopy {
        font-size: 24px;
        font-weight: bold;
        font-family: $font-family-serif;
        margin: 0;
        text-align: left;
        @include media-breakpoint-up(lg) {
          @include fontSizeLG(24);
          text-align: left;
        }
      }
      > .text {
        font-size: 14px;
        line-height: 2;
        margin-bottom: 10px;
        @include media-breakpoint-up(sm) {
          margin-bottom: 20px;
        }
        @include media-breakpoint-up(lg) {
          @include fontSizeLG(14);
        }
      }
      .moreBtn {
        @include media-breakpoint-up(sm) {
          max-width: 250px;
        }
      }
    }
    .img {
      padding: 0 6vw 60px;
      @include media-breakpoint-up(sm) {
        flex-basis: 50%;
        padding: 0;
        padding-right: 3vw;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .footer {
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(lg) {
      margin-top: calc(110 / 1400 * -100vw);
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
