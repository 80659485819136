.lineupArea {
  background-color: $black;
  .sectionHeader {
    .header {
      .logo {
        svg {
          fill: $white;
        }
      }
      .title-en {
        color: $white;
      }
      .title-ja {
        color: $white;
      }
    }
  }
  .lineup {
    .cont {
      color: $white;
    }
  }
}
