.pankuzu {
  padding: 15px 6vw;
}
.breadcrumb {
  margin: 0;
  a {
    color: $body-color;
    text-decoration: none;
  }
}
