.shopData {
  width: 100%;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
  .cont {
    margin-right: 80px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 80%;
    }
    .title {
      font-size: 16px;
      font-family: $font-family-en;
      padding-bottom: 20px;
    }
    .brandTable {
      display: table;
      padding: 20px 0px;
      border-top: 1px solid $gray-700;
      width: 100%;
      .item {
        font-size: 14px;
        display: flex;
        display: table-row;
        margin: 0;
        padding: 0;
        .name {
          font-weight: bold;
          display: table-cell;
          white-space: nowrap;
          width: 35%;
          margin: 0;
          padding: 8px 0;
          padding-right: 20px;
          @include media-breakpoint-up(sm) {
            padding-right: 40px;
            width: 30%;
          }
        }
        .text {
          display: table-cell;

          margin: 0;
          padding: 8px 0;

          .sns {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
              margin-right: 10px;
              @include media-breakpoint-up(sm) {
                margin-right: 20px;
              }
              a {
                color: $body-color;
                i {
                  font-size: 1.5em;
                  @include media-breakpoint-up(sm) {
                    font-size: 2em;
                  }
                }
                img {
                  width: 20px;
                  @include media-breakpoint-up(sm) {
                    width: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }

    // .address {
    //   .text {
    //     word-break: break-all;
    //   }
    // }
  }
  .map {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
}
