.menuItem {
  display: flex;
  align-items: flex-start;
  .img {
    margin-right: 25px;
    position: relative;
    // width: 100%;
    overflow: hidden;
    flex-basis: 36%;
    &::before {
      content: "";
      width: 100%;
      padding-top: calc(240 / 200 * 100%); //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    flex: 1;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .data {
      display: flex;
      gap: 20px;
      color: $gray-600;
      font-size: 15px;
      align-items: center;
      margin-bottom: 20px;
      .price {
        gap: 10px;
        display: flex;
        .circle {
          display: inline-block;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: #f2f2f2;
          text-align: center;
          line-height: 27px;
          font-size: 12px;
        }
        .number {
          padding-top: 1.5px;
        }
      }
    }
    .text {
      font-size: 14px;
      word-break: break-all;
    }
  }
}
