.profileArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 45px;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    gap: 30px;
  }
  @include media-breakpoint-up(lg) {
    gap: 60px;
  }
  > .cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 45px;
    width: 100%;
    @include media-breakpoint-up(sm) {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 45px 20px;
      align-items: center;
    }
    @include media-breakpoint-up(lg) {
      flex: 1;
      padding: 60px 0;
      gap: 60px 30px;
    }
    > .logo {
      width: 250px;
      height: 250px;
      border: 2px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      margin: 0 auto;
      @include media-breakpoint-up(sm) {
        width: 150px;
        height: 150px;
      }
      @include media-breakpoint-up(lg) {
        width: 180px;
        height: 180px;
      }
    }
    > .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        flex: 1;
        align-items: flex-start;
      }
      .title-ja {
        font-size: 30px;
        letter-spacing: 0.1em;
        line-height: 1;
        font-family: $font-family-sans-serif;
        font-weight: bold;
        margin: 0;
        @include media-breakpoint-up(lg) {
          font-size: 36px;
        }
      }
      .title-en {
        font-size: 15px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        line-height: 1;
        font-family: $font-family-en;
        font-weight: normal;
        margin: 0;
      }
    }
    > .body {
      @include media-breakpoint-up(sm) {
        flex-basis: 100%;
      }
      .catchCopy {
        font-size: 18px;
        line-height: 1.2;
        font-family: $font-family-sans-serif;
        font-weight: bold;
        margin-bottom: 1.2em;
        @include media-breakpoint-up(sm) {
          font-size: 21px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 24px;
          margin-bottom: 1.5em;
        }
      }
      .leadCopy {
        font-size: 14px;
        line-height: 1.75;
        margin-bottom: 1.75em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  > .img {
    @include media-breakpoint-up(sm) {
      flex-basis: 40%;
    }
    @include media-breakpoint-up(lg) {
      flex-basis: 50%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
